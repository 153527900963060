<template>
  <div class="range-block">
    <v-row>
      <v-col
        :cols="cols"
        md="6"
        class="pt-0 pr-0"
      >
        <TextField
          label="от"
          :value="value[0]"
          :rules="[rules.minValue, rules.maxValue]"
          @input="handleMinValueChange($event)"
          :style="inputStyle"
          :type="type"
        />
      </v-col>
      <v-col
        :cols="cols"
        md="6"
        class="pt-0"
      >
        <TextField
          label="до"
          :value="value[1]"
          @input="handleMaxValueChange($event)"
          :rules="[rules.minValue, rules.maxValue]"
          :style="inputStyle"
          :type="type"
        />
      </v-col>
      <div class="subtitle-2 top-title d-flex justify-space-around">
        {{ title }}
      </div>
    </v-row>
  </div>
</template>

<script>
import TextField from '@/components/base/TextField'

export default {
  name: 'Range',
  components: { TextField },
  props: {
    cols: {
      type: [Number, String],
      default: 6
    },
    value: {
      type: Array
    },
    range: {
      type: Array,
      required: true
    },
    inputStyle: {
      type: String,
      default: ''
    },
    title: String,
    sliderInput: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'number'
    }
  },
  data () {
    return {
      model: this.prepareModel(),
      rules: {
        minValue: value => (value >= this.range[0] || !value) || `Минимальное значение ${this.range[0]}`,
        maxValue: value => (value <= this.range[1] || !value) || `Максимальное значение ${this.range[1]}`
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.handleModel()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleMinValueChange (data) {
      this.model.minValue = data
      this.handleRangeInput()
    },
    handleMaxValueChange (data) {
      this.model.maxValue = data
      this.handleRangeInput()
    },
    // !!! обязательно трансформировать массив в объяект для адекватного обновления значений в полях
    prepareModel () {
      let model = {
        minValue: null,
        maxValue: null
      }
      if (this.value.length > 0) {
        model.maxValue = this.value[0]
      } else if (this.value.length > 1) {
        model.minValue = this.value[0]
        model.maxValue = this.value[1]
      }
      return model
    },
    handleModel () {
      if (this.value.length === 0) {
        this.model.minValue = null
        this.model.maxValue = null
      } else if (this.value.length === 1) {
        this.model.minValue = this.value[0]
      } else {
        this.model.minValue = this.value[0]
        this.model.maxValue = this.value[1]
      }
    },
    handleRangeInput () {
      this.$emit('input', [this.model.minValue, this.model.maxValue])
    }
  }
}
</script>

<style scoped>
  .range-block {
    margin: 1px;
    position: relative;
  }
  .top-title {
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 800px) and (min-width: 200px) {
    .top-title {
      position: absolute;
      top: -10px;
    }
    .range-block {
      padding-top: 15px;
      margin: 1px;
      position: relative;
    }
  }
</style>
