<template>
  <div>
    <vue-headful
      :title="'Меркурий недвижимость для людей'"
      :description="'Меркурий сайт недвижимости для людей это сайт где нет рекламных квартир. Все квартиры на нашем сайте можно посмотреть вживую. Вы можете разместить своё объявление о продаже.'"
    />
    <div class="section section-main">
      <em></em>
      <div class="d-flex flex-column justify-center flex-wrap align-center">
        <h1 class="title-h1 mt-4 text-center white--text text-uppercase">Квартиры в Ростове-на-дону
        </h1>
      </div>
      <div class="filter-block">
        <v-card :class="widthWindow < 1000 ? 'ma-2' : 'ma-7'">
          <Filters
            :value="query.filter"
            @input="handleFilterChange($event)"
            :is-loading="isLoading"
          />
        </v-card>

      </div>
    </div>
    <v-card
      style="background: #FAFAFA"
      class="main-blocks elevation-0 pa-7 pt-1 pl-0 pr-2 rounded-0"
    >
      <PanelBenefits class="box-1 d-flex flex-row"/>
      <OurCompilation class="box-2"/>
      <FindAgents class="box-3"/>
      <QualityBlock class="box-4"/>
      <Reviews class="box-5"/>
    </v-card>

  </div>
</template>

<script>
import list from '@/components/mixins/list'
import isLogged from '@/components/mixins/isLogged'
import Filters from '@/components/views/Sale/Filters'
import PanelBenefits from '@/views/Main/PanelBenefits'
import OurCompilation from '@/views/Main/OurCompilation'
import FindAgents from '@/views/Main/FindAgents'
import QualityBlock from '@/views/Main/QualityBlock'
import Reviews from '@/views/Main/Reviews'
import windowSize from '@/components/mixins/windowSize'

export default {
  mixins: [list, isLogged, windowSize],
  name: 'MainPage',
  components: { Reviews, QualityBlock, PanelBenefits, Filters, OurCompilation, FindAgents },
  data () {
    return {
      show: false,
      apiUrl: '/sale',
      isOpenSnackbar: true
    }
  },
  watch: {
    isLoading: {
      handler () {
        if (this.getHash && !this.isLoading) {
          setTimeout(() => {
            if (document.querySelector(this.getHash)) {
              this.$vuetify.goTo(this.getHash, { duration: 500, easing: 'easeInOutCubic' })
            }
          }, 10)
        }
      }
    }
  }
}
</script>

<style scoped>
  .filter-block {
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    margin: 10px;
    box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  .title-h1 {
    font-size: 52px;
    font-weight: 700;
  }
  .main-blocks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .box-1 {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .box-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
  }
  .box-3 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .box-4 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (min-width: 1200px) {
    .box-2 {
      grid-column-end: 3;
    }
    .box-3 {
      grid-column-start: auto;
      grid-column-end: auto;
      margin-left: 0
    }
  }
  @media (max-width: 800px) and (min-width: 200px) {
    .title-h1 span {
      display: block;
      line-height: 1;
    }
    .title-h1 {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .box-3 {
      width: auto !important;
    }
    .main-blocks {
      padding-right: 0 !important;
    }
  }
  @media (max-width: 1000px) {
    .main-blocks {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .box-3 {
      margin-left: 0;
      width: 542px;
    }
  }
</style>
