<template>
  <div class="d-flex flex-column">
    <div
      class="review"
      style="height:800px;overflow:hidden;position:relative;"
    >
      <iframe
        style="width:100%;height:100%;border: none;border-radius:8px;box-sizing:border-box"
        src="https://yandex.ru/maps-reviews-widget/43191116770?comments"
      ></iframe>
      <a
        href="https://yandex.ru/maps/org/an_merkuriy/43191116770/"
        target="_blank"
        style="box-sizing:border-box;text-decoration:none;color:#b3b3b3;font-size:10px;font-family:YS Text,sans-serif;padding:0 20px;position:absolute;bottom:8px;width:100%;text-align:center;left:0;overflow:hidden;text-overflow:ellipsis;display:block;max-height:14px;white-space:nowrap;padding:0 16px;box-sizing:border-box"
      >
        АН Меркурий на карте Ростова‑на‑Дону — Яндекс Карты
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reviews'
}
</script>

<style scoped>
  .review {
    width: 560px
  }
  @media (max-width: 560px) {
    .review {
      width: 400px
    }
  }
  @media (max-width: 400px) {
    .review {
      width: 300px
    }
  }
</style>
