<template>
  <v-select
    :value="value"
    @input="$emit('input', $event)"
    :items="items"
    :label="label"
    dense
    solo
    item-value="id"
    item-text="value"
    multiple
    hide-details
    outlined
  >
    <template v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        <span>{{ item.value }}</span>
      </div>
      <span
        v-if="index === 1"
        class="grey--text caption"
      >(+{{ value.length - 1 }} других)</span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'MultipleSelection',
  props: {
    items: Array,
    value: Array,
    label: String
  }
}
</script>

<style scoped>

</style>
