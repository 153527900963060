<template>
  <v-text-field
    :label="label"
    :value="value"
    @input="$emit('input', $event)"
    :rules="rules"
    :style="inputStyle"
    :type="type"
    dense
    solo
    :hide-details="hideDetails"
    :outlined="outlined"
  ></v-text-field>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    value: [Number, String],
    outlined: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    solo: Boolean,
    rules: Array,
    inputStyle: String,
    label: String,
    type: String
  }
}
</script>

<style scoped>
</style>
