<template>
  <v-select
    v-model="detail"
    label="Материал стен"
    :item-value="itemValue"
    :items="items"
    :item-text="itemText"
    :dense="dense"
    :solo="solo"
    class="elevation-0"
    :outlined="outlined"
    :hide-details="hideDetails"
  ></v-select>
</template>

<script>
export default {
  name: 'Select',
  props: {
    label: String,
    items: Array,
    value: [Object, Number],
    dense: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    solo: Boolean,
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'value'
    }
  },
  computed: {
    detail: {
      get () {
        return this.value
      },
      set (e) {
        this.$emit('input', e)
      }
    }
  }
}
</script>

<style scoped>
</style>
