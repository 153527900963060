export default {
  computed: {
    districtCustomItems () {
      return this.$store.getters['serviceData/optionsList']('building.district_custom')
    },
    districtOfficialItems () {
      return this.$store.getters['serviceData/optionsList']('building.district_official')
    },
    districtCustomItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.district_custom')
    },
    districtOfficialItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.district_official')
    },
    buildingMaterialItems () {
      return this.$store.getters['serviceData/optionsList']('building.building_material', true)
    },
    builderItems () {
      return this.$store.getters['serviceData/optionsList']('building.builder')
    },
    builderItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.builder')
    },
    buildingTypeItems () {
      return this.$store.getters['serviceData/optionsList']('building.building_type')
    },
    apartmentComplexItems () {
      return this.$store.getters['serviceData/optionsList']('building.apartment_complex')
    },
    apartmentComplexItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('building.apartment_complex')
    },
    managerCompanyItems () {
      return this.$store.getters['serviceData/optionsList']('building.manager_company')
    },
    buildingNumbers () {
      return this.buildingNumberList
    },
    subdistrictItems () {
      return this.$store.getters['serviceData/optionsList']('building.subdistrict')
    },
    flatTypeItems () {
      let list = this.$store.getters['serviceData/optionsList']('advert.flat_type')
      if (list && list.length) {
        list = list.filter(item => item.id !== 1488 && item.id !== 1490 && item.id !== 1534)
      }
      return list
    },
    flatTypeItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('advert.flat_type')
    },
    flatTypeList () {
      let result = this.flatTypeItems.filter((item) => item.id !== 7)
      return result
    },

    kitchenTypeItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('advert.kitchen_type')
    },
    flatStatusItems () {
      return this.$store.getters['serviceData/optionsList']('advert.flat_status', false)
    },
    flatStatusItemsWithEmpty () {
      return this.$store.getters['serviceData/optionsList']('advert.flat_status')
    },
    exclusiveStatusItems () {
      return this.$store.getters['serviceData/optionsList']('advert.exclusive_status', false)
    },
    ownerStatusLocalItems () {
      return this.$store.getters['serviceData/optionsList']('advert.owner_status_local', false)
    }
  }
}
