<template>
  <div>
    <MultipleSelection
      v-if="districtType === 'official'"
      v-model="localDistrictOfficial"
      :items="districtOfficialItemsWithEmpty"
      label="Район официальный"
    />
    <MultipleSelection
      v-if="districtType === 'custom'"
      v-model="localDistrictCustom"
      :items="districtCustomItemsWithEmpty"
      label="Район народный"
    />
  </div>
</template>

<script>
import MultipleSelection from '@/components/views/Sale/Filters/MultipleSelection'
import serviceDataComputed from '@/components/mixins/serviceDataComputed'

const DistrictTypeCustom = 'custom'
const DistrictTypeOfficial = 'official'

export default {
  mixins: [serviceDataComputed],
  name: 'DistrictFilter',
  components: { MultipleSelection },
  props: {
    districtCustom: Object,
    districtOfficial: Object,
    districtType: String
  },
  computed: {
    localDistrictCustom: {
      get () {
        return this.districtCustom ? Object.keys(this.districtCustom).map(item => item * 1) : []
      },
      set (val) {
        this.$emit('update:districtCustom', this.handleSelected(val))
      }
    },
    localDistrictOfficial: {
      get () {
        return this.districtOfficial ? Object.keys(this.districtOfficial).map(item => item * 1) : []
      },
      set (val) {
        this.$emit('update:districtOfficial', this.handleSelected(val))
      }
    }
  },
  watch: {
    districtType: {
      handler () {
        if (this.districtType === DistrictTypeCustom) {
          this.$emit('update:districtOfficial', null)
        } else if (this.districtType === DistrictTypeOfficial) {
          this.$emit('update:districtCustom', null)
        }
      }
    }
  },
  methods: {
    handleSelected (data) {
      let districts = null
      if (data.length) {
        districts = {}
        data.forEach(item => {
          if (!isNaN(item)) {
            if (!districts.hasOwnProperty(item.id)) {
              districts[item] = [null].concat(this.subdistrictItems.filter(subdistrict => subdistrict.parent_id === item).map(subdistrict => subdistrict.id))
            }
          }
        })
      }
      return districts
    }
  },
  mounted () {
    if (this.districtOfficial) {
      this.$emit('changeDistrict', DistrictTypeOfficial)
    }
  }
}
</script>

<style scoped>

</style>
