<template>
  <v-autocomplete
    v-if="mask"
    :value="value"
    @input="model = $event"
    :items="getItems"
    :item-value="itemValue"
    :item-text="itemText"
    :loading="loading"
    :search-input.sync="search"
    :label="placeholder"
    :cache-items="false"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    return-object
    v-mask="mask"
    :clearable="clearable"
    :outlined="outlined"
    :dense="dense"
    :solo="solo"
  >
  </v-autocomplete>
  <v-autocomplete
    v-else
    :value="value"
    @input="model = $event"
    :items="getItems"
    :item-value="itemValue"
    :item-text="itemText"
    :loading="loading"
    :search-input.sync="search"
    :cache-items="false"
    :label="placeholder"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    return-object
    :clearable="clearable"
    :outlined="outlined"
    :dense="dense"
    :solo="solo"
  >
    <template v-slot:append-item>
      <slot
        name="appendItem"
      ></slot>
    </template>
  </v-autocomplete>
</template>

<script>
// todo: найти способ убрать костыль с маской
import { mask } from '@titou10/v-mask'
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  name: 'Autocomplete',
  directives: {
    mask
  },
  props: {
    value: {},
    mask: {
      default: false
    },
    placeholder: String,
    appendIcon: String,
    prependIcon: String,
    itemText: String,
    itemValue: String,
    url: String,
    charsToSearch: {
      type: Number,
      default: 5
    },
    clearable: Boolean,
    outlined: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    solo: { type: Boolean, default: false }
  },
  data: () => ({
    items: [],
    loading: false,
    search: '',
    model: ''
  }),
  watch: {
    search: {
      handler: async function () {
        if (!this.search) {
          this.model = ''
          this.items = []
        } else if (!this.loading && this.search.length > this.charsToSearch && (!this.model || (this.search !== this.model[this.itemText]))) {
          let autocompleteSting = this.mask ? this.search.split(' ').join('') : this.search
          await this.autocomplete(autocompleteSting)
        }
        this.$emit('changeNumber', this.search)
      }
    },
    model: {
      handler: function () {
        this.$emit('input', this.model)
      },
      deep: true
    },
    value: {
      handler (val) {
        if (val.id) {
          this.items.push(this.value)
          this.model = this.value
          this.search = this.model[this.itemText]
        }
      },
      deep: true
    }
  },
  computed: {
    getItems () {
      return this.items
    }
  },
  methods: {
    async autocomplete (search) {
      this.loading = true
      const response = await this.$store.dispatch('server/autocomplete', {
        url: this.url,
        queryString: search
      })
      if (response && response.length) {
        this.items = this.processItems(response)
      }
      this.loading = false
      return true
    },
    processItems (items) {
      if (this.mask) {
        items.forEach(item => {
          item.phone = this.maskValue(item.phone, this.mask)
        })
      }
      return items
    }
  },
  created () {
    if (this.value) {
      this.items.push(this.value)
      this.model = this.value
      this.search = this.model[this.itemText]
    }
  }
}
</script>

<style scoped>

</style>
