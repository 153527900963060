import { cleanClone, processGetData } from '@/components/utils/common'
import Qs from 'qs'

export default {
  props: {
    pageUrl: {
      type: String,
      default: ''
    },
    cmsData: Object
  },
  data: () => ({
    isLoading: false,
    items: {},
    query: {
      filter: {},
      pagination: {
        perPage: '100',
        pageNumber: 1
      },
      sort: {
        /*published_at: 'DESC'*/
      }
    },
    apiUrl: ''
  }),
  computed: {
    getHash () {
      return this.$route.hash ? this.$route.hash.split('?')[0] : null
    },
    getUrl () {
      return this.pageUrl ? this.pageUrl : this.apiUrl
    }
  },
  watch: {
    query: {
      handler: 'handleQueryChange',
      deep: true
    },
    '$route.fullPath': {
      handler: 'handleRouteChange'
    }
  },
  methods: {
    parseQueryObjectFromUrl (queryString) {
      const queryObject = Qs.parse(queryString)
      this.query.filter = queryObject.filter || {}
      if (queryObject.pagination) {
        this.query.pagination = { ...this.query.pagination, ...queryObject.pagination }
      }
      if (queryObject.sort) {
        this.query.sort = queryObject.sort
      }
    },
    getUrlStringFromQueryObject () {
      let url = cleanClone(this.getUrl)
      if (this.getHash) {
        url += this.getHash
      }
      return url + '?' + Qs.stringify(processGetData(cleanClone(this.query)), {
        arrayFormat: 'indices',
        encode: false
      })
    },
    async handleQueryChange () {
      let path = this.getUrlStringFromQueryObject()
      if (decodeURI(this.$route.fullPath) !== path) {
        await this.$router.push({ path }, this.fillData)
      }
      return true
    },
    async handleRouteChange () {
      let self = this
      if (self.$route.query?.clear) {
        this.$set(this.query, 'filter', {})
      } else {
        await this.handleQueryChange()
      }
      return true
    },
    handleFilterChange (data) {
      this.query.pagination.pageNumber = 1
      this.$set(this.query, 'filter', data)
    },
    // todo: refactor
    async fillData () {
      if (!this.isLoading) {
        let url = this.$route.fullPath
        let apiUrl = this.apiUrl
        let queryString = url.split('?')[1]
        if (queryString) {
          this.parseQueryObjectFromUrl(queryString)
        } else {
          queryString = this.getUrlStringFromQueryObject().split('?')[1]
        }
        apiUrl += '?' + queryString
        this.isLoading = true
        this.items = await this.$store.dispatch('server/get', { url: apiUrl }) || { models: [], count: 0 }
        // если указана несуществующая страница - сбрасываем до 1
        if (this.items.count && !this.items.models.length) {
          this.query.pagination.pageNumber = 1
        }
        this.isLoading = false
      }
      return true
    }
  },
  async mounted () {
    return this.fillData()
  },
  async created () {
    if (this.cmsData && this.cmsData.compilation) {
      this.query.filter = this.cmsData.compilation
    }
  }
}
