<template>
  <v-card
    :max-width="width >= 1200 ? 'auto' : 550"
    class="d-flex elevation-0 pa-4 compilation"
  >
    <v-img
      max-width="251"
      :src="compilation"
    />
    <div class="pt-3">
      <p class="font-weight-bold body-2 text-uppercase">Наши подборки</p>
      <p
        class="blue--text ma-0 subtitle-2"
        :key="index"
        v-for="(item, index) in compilations"
        @click="goToDetail(item)"
      > {{ item.header }}</p>
    </div>
  </v-card>
</template>

<script>
import compilation from '@/assets/compilation.png'
import isMobile from '@/components/mixins/isMobile'

export default {
  name: 'OurCompilation',
  mixins: [isMobile],
  data () {
    return {
      compilation,
      compilations: []
    }
  },
  methods: {
    async getCms () {
      const cms = await this.$store.dispatch('serviceData/getCmsPages', { perPage: 6 })
      this.compilations = cms || []
    },
    goToDetail (item) {
      this.$router.push({ path: item.url })
    }
  },
  mounted () {
    this.getCms()
  }
}
</script>

<style>
  @media (max-width: 500px) {
    .compilation {
      flex-direction: column !important;
      gap: 10px !important;
      align-items: center;
      width: auto !important;
    }
  }
  @media (max-width: 1120px) {
    .compilation {
      min-width: auto !important;
      flex-direction: row-reverse;
    }
  }
  .compilation {
    gap: 30px;
    min-width: 500px;
  }
</style>
