<template>
  <div class="d-flex">
    <v-col
      cols="7"
      class="pt-0 pl-0"
    >
      <Autocomplete
        v-model="streetFilter"
        item-value="streetId"
        item-text="name"
        placeholder="Улица"
        url="/street/autocomplete"
        :chars-to-search="2"
        dense
        outlined
        solo
      />
    </v-col>
    <v-col
      cols="5"
      class="pt-0 pr-0"
    >
      <v-autocomplete
        v-model="buildingNumberFilter"
        :items="buildingNumbersWithEmpty"
        label="Дом"
        item-value="id"
        item-text="building_number"
        :loading="buildingLoading"
        :disabled="!streetFilter"
        @focus="fillBuildingNumbers"
        return-object
        dense
        solo
        outlined
      ></v-autocomplete>
    </v-col>
  </div>
</template>

<script>
import Autocomplete from '@/components/views/Common/Autocomplete'
import { deepEqual } from '@/components/utils/common'

export default {
  name: 'AddressFilter',
  components: { Autocomplete },
  props: {
    streetId: Number,
    buildingNumberId: Number
  },
  data () {
    return {
      buildingLoading: false,
      buildingNumberList: [],
      streetFilter: null,
      buildingNumberFilter: null
    }
  },
  computed: {
    buildingNumbersWithEmpty () {
      this.buildingNumberList.unshift({ id: null, building_number: 'Не указано' })
      return this.buildingNumberList
    }
  },
  watch: {
    streetFilter: {
      handler (newVal, oldVal) {
        let streetId = null
        if (this.streetFilter) {
          streetId = this.streetFilter.id
        }
        if (!deepEqual(newVal, oldVal)) {
          this.buildingNumberFilter = null
          this.buildingNumberList = []
        }
        this.$emit('update:streetId', streetId)
      },
      deep: true
    },
    buildingNumberFilter: {
      handler (newVal, oldVal) {
        let buildingId = null
        if (this.buildingNumberFilter) {
          buildingId = this.buildingNumberFilter.id
        }
        if (!deepEqual(newVal, oldVal)) {
          this.$emit('update:buildingNumberId', buildingId)
        }
      },
      deep: true
    }
  },
  methods: {
    async fillBuildingNumbers () {
      if (this.streetFilter) {
        this.buildingLoading = true
        const response = await this.$store.dispatch('server/get', { url: '/address/building-numbers?streetId=' + this.streetFilter.id })
        if (response) {
          this.buildingNumberList = response
        }
        this.buildingLoading = false
      }
      return true
    },
    async fillStreetFilter () {
      if (this.streetId) {
        this.buildingLoading = true
        const response = await this.$store.dispatch('server/get', { url: '/street/detail/' + this.streetId })
        if (response && response.length) {
          this.streetFilter = response[0]
        }
        this.buildingLoading = false
      }
      return true
    }
  },
  async mounted () {
    await this.fillStreetFilter()
    await this.fillBuildingNumbers()
    this.buildingNumberFilter = this.buildingNumberList.find(item => item.id === this.buildingNumberId)
    return true
  }
}
</script>

<style scoped>

</style>
