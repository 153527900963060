<template>
  <v-card
    max-width="700"
    class="d-flex justify-center elevation-0 flex-row pa-5 agents"
  >
    <div class="align-self-center d-flex flex-column align-start align-xs-center info-text mr-10">
      <p class="font-weight-bold text-uppercase text-body-1 find-title">Ищем Агентов </p>
      <p
        class="text-uppercase subtitle-2"
        style="max-width: 250px"
      >Если ты молодой активный и хочешь зарабатывать - звони!</p>
      <p class="font-weight-bold blue--text text-h5">+7 988 257-44-45</p>
    </div>
    <v-img
      max-width="212"
      :src="womanCut"
    />
  </v-card>
</template>

<script>
import womanCut from '@/assets/woman-cut.png'

export default {
  name: 'FindAgents',
  data () {
    return {
      womanCut
    }
  }
}
</script>

<style scoped>
  @media (max-width: 500px) {
    .find-title {
      padding-top: 20px;
    }
    .agents {
      flex-direction: column-reverse !important;
      align-content: center;
      justify-items: center !important;
      justify-content: center !important;
    }
    .info-text {
      align-items: center !important;
      text-align: center;
      padding-top: 4px;
      margin-right: 0 !important;
    }
    .v-image {
      margin: 0 auto;
    }
  }
  /deep/ .v-responsive__content {
    width: 251px !important;
  }
  /deep/ .v-image__image--cover {
    background-size: contain;
  }
</style>
