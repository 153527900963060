<template>
  <v-card class="d-flex flex-row elevation-0 justify-space-between pa-4 align-center pl-6 benefits">
    <div
      class="pr-4 d-flex flex-row align-center"
      v-for="(item, index) in benefits"
      :key="index"
    >
      <v-icon size="42">
        {{ item.icon }}
      </v-icon>
      <p
        style="max-width: 186px"
        class="text-uppercase ma-0 ml-2 body-2"
      >
        {{ item.text }}
      </p>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'PanelBenefits',
  data () {
    return {
      benefits: [
        {
          text: 'Помощь в одобрении ипотеки',
          icon: '$vuetify.icons.home'
        }, {
          text: 'Новостройки без % по всей России',
          icon: '$vuetify.icons.multipleBuildings'
        }, {
          text: 'все квартиры Ростова-на-Дону на вторичном рынке',
          icon: '$vuetify.icons.yellowBuildings'
        }, {
          text: 'Все виды страхования',
          icon: '$vuetify.icons.multipleBuildings'
        }
      ]
    }
  }
}
</script>

<style scoped>
  @media (max-width: 800px) {
    .benefits {
      flex-direction: column !important;
      gap: 15px;
    }
  }
</style>
